<template>
  <div class="robot-address">
    <div class="cell">
      <el-tabs @tab-click="handleQH" v-model="activeName" type="border-card">
        <el-tab-pane name="management" label="管理">
          <el-table :data="tableData.list" style="width: 100%">
            <el-table-column prop="name" label="用户姓名" align="center"></el-table-column>
            <el-table-column align="center" prop="mobile" label="联系方式">
            </el-table-column>
            <el-table-column align="center" prop="country" label="国家">
            </el-table-column>
            <el-table-column width="190" align="center" label="省、市、区">
              <template slot-scope="scope">
                <span>{{ scope.row.province }}</span>- <span>{{ scope.row.city }}</span>-
                <span>{{ scope.row.district }}</span>
              </template>
            </el-table-column>
            <el-table-column width="240" align="center" prop="address" label="详细地址">
            </el-table-column>
            <el-table-column align="center" prop="zip_code" label="邮编">
            </el-table-column>
            <el-table-column align="center" prop="status" label="状态">
              <template slot-scope="scope">
                <el-tag type="success" v-if="scope.row.status == 1">启用</el-tag>
                <el-tag v-else type="info">停用</el-tag>
              </template>
            </el-table-column>
            <el-table-column width="200" align="center" prop="address" label="操作">
              <template slot-scope="scope">
                <el-button size="mini" @click="handleEdit(scope.row)">编辑</el-button>
                <el-button v-if="scope.row.status == 0" size="mini" type="success"
                  @click="handleONandOFF(scope.row, 1)">启用</el-button>
                <el-button v-else size="mini" type="danger" @click="handleONandOFF(scope.row, 0)">停用</el-button>
              </template>
            </el-table-column>
          </el-table>

          <el-pagination small style="padding: 20px 10px" background :page-size="page_size" :current-page="page"
            @current-change="handlePage" layout="prev, pager, next" :total="tableData.total">
          </el-pagination>
        </el-tab-pane>
        <el-tab-pane name="add" :label="title">
          <el-form size="small " ref="form" :rules="rules" :model="form" label-width="120px" label-position="left"
            style="width: 300px">
            <el-form-item prop="name" label="姓名:">
              <el-input style="width: 300px" v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item prop="mobile" label="电话号码:">
              <el-input style="width: 300px" maxlength="11" v-model.number="form.mobile" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item prop="country" label="国家:">
              <el-select style="width: 300px" v-model="form.country" :disabled="true" placeholder="请选择活动区域">
                <el-option label="中国" value="中国"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item prop="province" label="省/市/区:">
              <el-cascader v-if="acc || value.length != 0" size="small" style="width: 300px" v-model="value"
                :options="options" @change="handleChange" placeholder="省市区"></el-cascader>
            </el-form-item>

            <el-form-item prop="zip_code" label="邮政编码:">
              <el-input style="width: 300px" v-model="form.zip_code" placeholder="邮政编码"></el-input>
            </el-form-item>
            <el-form-item prop="address" label="详细地址:">
              <el-input style="width: 300px" :rows="4" type="textarea" v-model="form.address"
                placeholder="街道地址、邮政信箱、公司名称、代收地址"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSubmit">提交</el-button>
              <el-button @click="handleBlcak">取消</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import { getAddressList, saveAddress, changeStatusAddr } from "@/api/admin.js";
import areaList from "@/assets/js/areaList.js";
export default {
  data() {
    return {
      activeName: "management",
      tableData: {
        list: [],
      },
      page: 1,
      page_size: 10,
      form: {
        id: "0",
        name: "",
        mobile: "",
        mobile_prefix: "+86",
        country: "中国",
        province: "",
        city: "",
        district: "",
        province_code: "",
        city_code: "",
        district_code: "",
        address: "",
        zip_code: "",
        status: "",
      },
      rules: {
        name: [{ required: true, message: "必填", trigger: "blur" }],
        mobile: [{ required: true, message: "必填", trigger: "blur" }],
        country: [{ required: true, message: "必填", trigger: "blur" }],
        province: [{ required: true, message: "必选", trigger: "change" }],
        city: [{ required: true, message: "必填", trigger: "blur" }],
        district: [{ required: true, message: "必填", trigger: "blur" }],
        address: [{ required: true, message: "必填", trigger: "blur" }],
        zip_code: [{ required: true, message: "必填", trigger: "blur" }],
      },
      areaList: [],
      options: [],
      value: [],
      title: "新增",
      acc: false,
    };
  },
  created() {
    this.getList();
    this.getArea();
  },
  methods: {
    getList() {
      getAddressList({
        page: this.page,
        page_size: this.page_size,
      }).then((res) => {
        if (res.code == 1) {
          this.tableData = res.data;
        }
      });
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          saveAddress({
            ...this.form,
          }).then((res) => {
            if (res.code == 1) {
              this.activeName = "management";
              this.$message.success(this.title + "成功");
              if (this.title == "新增") {
                this.page = 1;
              }
              this.title = "新增";
              this.getList();
              this.acc = false;
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    getArea() {
      Object.keys(areaList.province_list).forEach((item, i) => {
        this.options[i] = {
          value: item + "," + areaList.province_list[item],
          label: areaList.province_list[item],
          children: [],
        };
        Object.keys(areaList.city_list).forEach((val) => {
          let cityId = item.slice(0, 2);
          if (cityId == val.slice(0, 2)) {
            this.options[i].children.push({
              value: val + "," + areaList.city_list[val],
              label: areaList.city_list[val],
              children: [],
            });
          }
        });
      });
      this.options.forEach((item) => {
        item.children.forEach((val) => {
          Object.keys(areaList.county_list).forEach((vals) => {
            let cityId = val.value.slice(0, 4);
            if (cityId == vals.slice(0, 4)) {
              val.children.push({
                value: vals + "," + areaList.county_list[vals],
                label: areaList.county_list[vals],
              });
            }
          });
        });
      });
    },
    handleChange(value) {
      this.form.province_code = value[0].split(",")[0];
      this.form.province = value[0].split(",")[1];
      this.form.city_code = value[1].split(",")[0];
      this.form.city = value[1].split(",")[1];
      this.form.district_code = value[2].split(",")[0];
      this.form.district = value[2].split(",")[1];
    },
    handleBlcak() {
      this.$refs.form.resetFields();
      this.value = [];
      this.activeName = "management";
      this.title = "新增";
      this.acc = false;
    },
    handleEdit(val) {
      this.activeName = "add";
      this.title = "编辑";
      let data = JSON.stringify(val);
      this.form = JSON.parse(data);
      this.value[0] = this.form.province_code + "," + this.form.province;
      this.value[1] = this.form.city_code + "," + this.form.city;
      this.value[2] = this.form.district_code + "," + this.form.district;
    },
    handleQH(e) {
      if (e.name == "management") {
        this.title = "新增";
        this.$refs.form.resetFields();
        this.value = [];
      } else {
        this.form = {
          id: "0",
          name: "",
          mobile: "",
          mobile_prefix: "+86",
          country: "中国",
          province: "",
          city: "",
          district: "",
          province_code: "",
          city_code: "",
          district_code: "",
          address: "",
          zip_code: "",
          status: "",
        };
        this.value = [];
        this.$refs.form.resetFields();
        this.acc = true;
      }
    },
    handleONandOFF(val, type) {
      changeStatusAddr({
        id: val.id,
        status: type,
      }).then((res) => {
        if (res.code == 1) {
          this.$message.success("操作成功");
          this.getList();
        }
      });
    },
    handlePage(val) {
      this.page = val;
      this.getList();
    },
  },
};
</script>
<style lang="less" scoped>
.robot-address {
  padding: 20px;

  .cell {
    // padding: 10px;
    background: #fff;
  }
}
</style>